import { httpGet } from "./HttpService";



export default class FeedbackService {
    private static instance: FeedbackService;
    private constructor() { }
    public static getInstance() {
        if (!this.instance) {
            this.instance = new FeedbackService();
        }
        return this.instance;
    }

    async getListFeedback(page: number, limit: number, name: string) {
        const res = await httpGet(`/dashboard/feedback?page=${page}&limit=${limit}&name=${name}`);
        return res.data;
    }

    async getListGeneratedImage(page: number, limit: number, orderType: 1 | 0 | -1 | undefined | null, order: string | undefined, filter: string | undefined) {
        const res = await httpGet(`/dashboard/generated-image?page=${page}&limit=${limit}&order=${order}&filter=${filter}&orderType=${orderType}`);
        return res.data;
    }

    async getListGeneratedImageAnime(page: number, limit: number, orderType: 1 | 0 | -1 | undefined | null, order: string | undefined, filter: string | undefined) {
        const res = await httpGet(`/dashboard/generated-image-anime?page=${page}&limit=${limit}&order=${order}&filter=${filter}&orderType=${orderType}`);
        return res.data;
    }

    async getImageDetail(id: string) {
        const res = await httpGet(`/dashboard/generated-image-detail/${id}`);
        return res.data;
    }
}
